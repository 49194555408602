<template>
  <div class="proyect">
    <Header
      nameProject="Mimo"
      subtitle="Web para concienciar sobre la radiación UV"
      img="img/mimoweb.png"
    />

    <Section
      intro="El proyecto Mimo consiste en una web para informar y concienciar a las personas del uso de protección contra la radiación solar.
"
      description="El objetivo de este proyecto era crear una web que sirviera como herramienta para ayudar, en este caso, informando y concienciando sobre la radiación solar. 
Tras una investigación se comprobó que hay mucho desconocimiento acerca de la radiación solar y cómo nos afecta. Aunque el sol puede ser muy bueno para nuestra piel, si nos pasamos de la exposición con radiación muy alta nos perjudica gravemente, no solo quemándonos, dañando nuestros ojos y sistema inmunitario o acelerando el envejecimiento, también aumentando las posibilidades de sufrir cáncer de piel.
"
      fecha="Mayo 2020 - Septiembre 2020"
      rol="Product designer"
      servicios="Research · UI Design · UX Design"
      title="Problema"
      problem="El problema a resolver en este proyecto es crear una solución que sirva como herramienta para concienciar. Se buscaba poner conciencia e informar a las personas de los niveles de radiación solar y cómo protegerse en cada momento. Además conocer su fototipo de piel personalizando las necesidades y consejos  según el nivel de protección solar."
    />

    <div class="casestudy container">
          <img class="caseImg" src="img/mimo_all.png" alt="Pantallas del proyecto Mimo" />
      <h3>Proximamente case study</h3>
      <div>〰</div>
      <ul class="pagination">
        <li v-for="item in object" :key="item" class="project">
          <router-link :to="{ path: item.link }" class="link"
            >{{ item.msgButton }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Section from "@/components/Section.vue";

export default {
  name: "Mimo",
  components: {
    Header,
    Section,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      object: [
        {
          link: "/Mibus",
          img: "img/vera.png",
          msgButton: "← Mibus",
        },
        {
          link: "/Vera",
          img: "img/aula.png",
          msgButton: "Vera →",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.casestudy {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    margin: 6rem auto 2rem auto;
  }

  ul {
    margin: 3rem auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0;

    li {
      display: flex;
      text-decoration: none;
      align-items: center;

      a {
        font-size: 2.5rem;
        font-family: "DM Serif Display", sans-serif;
      }
    }
  }
}
</style>
